import React, { useState } from 'react';
import { graphql }         from 'gatsby';
import Img                 from 'gatsby-image';

import { findT }  from '@interness/web-core/src/components/_helpers';
import Link       from '@interness/web-core/src/components/elements/Link/Link';
import routes     from '@interness/web-core/config/routes';
import Logo       from '@interness/web-core/src/components/media/Logo/Logo';
import Button     from '@interness/web-core/src/components/elements/Button/Button';
import SEO        from '@interness/web-core/src/components/modules/SEO/SEO';
import BrandsSubnavigation
                  from '@interness/brands-addon/src/components/modules/BrandsSubnavigation/BrandsSubnavigation';
import Spacer     from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Wrapper    from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Heading    from '@interness/web-core/src/components/text/Heading/Heading';
import RandomBrandHeaderImages
                  from '@interness/brands-addon/src/components/media/RandomBrandHeaderImages/RandomBrandHeaderImages';
import CTASection from '@interness/web-core/src/components/modules/CTASection/CTASection';

import * as S from './styles';

const GridItem = ({ brand, lang }) => {
  let touched = false;
  const [hovering, setHovering] = useState(false);
  if (brand.gallery_images_square.length === 0) {
    console.log(brand.display_name);
  }
  return (
    <Link to={`${routes[brand.type.type][lang]}/${brand.slug}`}
          onTouchStart={() => (touched = true)}
          onMouseEnter={() => {
            if (!touched) {
              setHovering(true)
            }
          }}
          onMouseLeave={() => {
            if (!touched) {
              setHovering(false)
            }
          }}
    >
      <S.GridItem hovering={hovering}>
        <S.Overlay>
          <p>{brand.display_name}</p>
        </S.Overlay>
        <S.BrandLogo visibility={hovering ? 'visible' : 'hidden'}>
          <Logo logo={brand.logo.localFile} title={brand.display_name} svgFill={'#000'} svgWidth='240px' svgHeight='80px'/>
          <Button>Mehr erfahren</Button>
        </S.BrandLogo>
        <Img className='background' fluid={brand.gallery_images_square[0].file.localFile.childImageSharp.fluid}
             alt={brand.display_name}/>
      </S.GridItem>
    </Link>
  )
};

const BrandsPageTemplate = props => {
  const { type, lang } = props.pageContext;
  const brands = props.data.allDirectusBrands.nodes;
  const brandInfo = findT(props.data.directusBrandTypes.translations, lang);
  return (
    <>
      <SEO title={brandInfo.display_name}/>
      <BrandsSubnavigation type={type}/>
      <Spacer height={20}/>
      <Wrapper>
        <Heading
          subtitle={`bei ${findT(props.data.directusProject.translations, lang).seo_title}`}>{brandInfo.display_name}</Heading>
        <S.TypeDescription dangerouslySetInnerHTML={{ __html: brandInfo.description }}/>
        <Spacer height={20}/>
      </Wrapper>
      <RandomBrandHeaderImages type={type}/>
      <Spacer height={40}/>
      <Wrapper>
        <h3 style={{ textAlign: 'center' }}>Unsere Markenwelt: {brandInfo.display_name}</h3>
        <Spacer height={30}/>
        <S.Grid>
          {brands.map(({ brand }) => {
            return (
              <GridItem brand={brand} key={`${brand.type.type}/${brand.display_name}`} lang={lang}/>
            )
          })}
        </S.Grid>
      </Wrapper>
      <Spacer/>
      <CTASection/>
    </>
  )
};

export const query = graphql`
  query($type: String!) {
    directusBrandTypes(type: {eq: $type}) {
      type
      translations {
        language
        display_name
        subtitle
        description
        short_description
      }
    }
    allDirectusBrands(filter: {brand: {type: {type: {eq: $type}}, status: {eq: "published"}}, id: {ne: "dummy"}}, sort: {fields: brand___brand_rating, order: DESC}) {
      nodes {
        ...BrandsCore
        brand {
          gallery_images_square {
            file {
              width
              height
              localFile {
                name
                childImageSharp {
                  fluid(maxWidth: 250, maxHeight: 250, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        }
      }
    }
    directusProject {
      translations {
        seo_title
        language
      }
    }
  }
`;

export default BrandsPageTemplate;